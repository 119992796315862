import arrow from "assets/images/arrowDropdown.svg";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

export const AutoCompleteComparedStyles = {
    "& .MuiAutocomplete-clearIndicator": {
        marginRight: "2rem !important",
        visibility: "visible !important"
    },
    "& .MuiAutocomplete-endAdornment": {
        top: "unset !important"
    },

    "& .MuiAutocomplete-input": {
        fontWeight: 400,
        color: theme`colors.blue.600`,
        fontSize: "0.9rem"
    },
    "& .MuiAutocomplete-input::placeholder": {
        opacity: 1,
        color: theme`colors.blue.600`,
        fontFamily: "Noto Sans !important"
    },
    "& .MuiAutocomplete-popupIndicator svg": {
        display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 0
    },
    "& .MuiTextField-root": {
        background: `url(${arrow}) no-repeat 100%`,
        backgroundPosition: "right 1rem center",
        borderBottom: "1.5px solid #1E2DBE",
        width: "9.875rem"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "0.1rem",
        paddingBottom: "0.1rem",
        fontSize: "0.75rem",
        lineHeight: "20px",
        paddingLeft: "3rem"
    }
};

export const AutoCompleteComparedOptionStyles = {
    color: theme`colors.blue.600`,
    display: "flex",
    fontSize: "0.8rem",
    gap: "1rem",
    width: "100%",
    padding: "0.65rem 1rem"
};

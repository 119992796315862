import { useAfricanSocialProtection } from "context/africanSocialProtection";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { years } from "utils/Slider";
import { IsFromSection } from "types/slider.types";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const StyledSlider = styled(Slider)(() => ({
    ".MuiSlider-mark": {
        borderRadius: 50,
        height: "0.5rem",
        width: "0.5rem",
        backgroundColor: theme`colors.blue.100`,
        border: `solid 1px ${theme`colors.blue.300`}`
    },
    ".MuiSlider-markLabel": {
        color: theme`colors.blue.300`,
        fontSize: "0.875rem",
        fontWeight: 300
    },
    ".MuiSlider-markLabelActive": {
        fontSize: "0.875rem",
        fontWeight: 600,
        color: theme`colors.blue.600`
    },
    ".MuiSlider-rail": {
        color: theme`colors.blue.300`,
        height: "1px",
        opacity: 1
    },
    ".MuiSlider-thumb": {
        height: "1.5rem",
        width: "1.5rem",
        backgroundColor: theme`colors.blue.100`,
        border: `solid 1px ${theme`colors.blue.600`}`
    },
    ".MuiSlider-markActive": {
        color: theme`colors.grey.400`
    }
}));

const YearFilter = ({ isFromMap, isFromHeroSection }: IsFromSection) => {
    const { year, setActiveFilter } = useAfricanSocialProtection();

    const handleChange = (_: Event, value: number | number[]) => {
        setActiveFilter({
            value,
            name: "year"
        });
    };

    return (
        <article
            css={[
                tw`w-full max-w-[57rem]`,
                isFromHeroSection && tw`absolute bottom-[7vh]`,
                isFromMap && tw`absolute left-[50%] translate-x-[-50%] bottom-0`
            ]}
        >
            <StyledSlider
                track={false}
                getAriaLabel={() => "Year slider marks"}
                getAriaValueText={(value) => value.toString()}
                marks={years}
                min={2016}
                max={2025}
                onChange={handleChange}
                value={year || Number(year)}
                tw="m-0 p-0"
            />
        </article>
    );
};

export default YearFilter;

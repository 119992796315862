const ClearIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.5 4L4.5 20M20.5 20L4.5 4"
            stroke="#1E2DBE"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
        />
    </svg>
);

export default ClearIcon;

import { CommonKeysPair } from "types/common-keys-pair.types";

export const languages: CommonKeysPair[] = [
    { name: "ENG", id: "eng" },
    { name: "FRA", id: "fra" }
];

export const links: CommonKeysPair[] = [
    { name: "Home", id: "home" },
    { name: "Map", id: "map" },
    { name: "About us", id: "about_us" }
];

export const countryLinks: CommonKeysPair[] = [
    { name: "overview_link", id: "overview" },
    { name: "trends", id: "trends" },
    { name: "comparison", id: "comparison" }
];

export const scenarios: CommonKeysPair[] = [
    { name: "Business as usual", id: "BASE" },
    { name: "Towards 40% goal by 2025", id: "OPTIMISTIC" },
    { name: "Social protection stagnation", id: "PESSIMISTIC" }
];

export const availableCountriesILO: string[] = [
    "TN",
    "MA",
    "EG",
    "SD",
    "ET",
    "MR",
    "SN",
    "SL",
    "CI",
    "GH",
    "TG",
    "BF",
    "NE",
    "NG",
    "CM",
    "KE",
    "UG",
    "CD",
    "AO",
    "ZM",
    "BI",
    "RW",
    "TZ",
    "MZ",
    "MG",
    "ZA",
    "SZ",
    "MW"
];

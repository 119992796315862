import { t } from "i18next";
import logo from "assets/images/mobileLogo.svg";
import ILOLogo from "assets/images/ILOMobile.svg";
import mobile from "assets/images/mobile.svg";
import bgUsual from "components/ErrorBoundary/assets/images/404Bg.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MobileUnavailable = () => (
    <>
        <header tw="leading-[0.8rem] m-auto flex justify-between bg-blue-100 p-[1rem 2rem]">
            <div tw="flex items-center gap-[1rem]">
                <img src={logo} alt="Logo" />
                <h1 tw="text-[0.4rem]">
                    <span tw="uppercase text-blue-600 font-bold">
                        {t("title")}
                    </span>
                    <p tw="text-purple-400">{t("sub_title")}</p>
                </h1>
            </div>
            <img src={ILOLogo} alt="ILO logo" width={100} />
        </header>
        <section
            tw="text-center bg-center bg-no-repeat bg-cover h-[calc(100vh - 4.43rem)] w-full m-auto"
            style={{
                backgroundImage: `url(${bgUsual})`
            }}
        >
            <div tw="max-w-[15rem] pt-[2rem] flex items-center m-auto flex-col gap-[1rem]">
                <img src={mobile} alt="Mobile" />
                <h3 tw="text-blue-600 font-bold text-[2.625rem] leading-[3rem]">
                    {t("mobile_unavailable")}
                </h3>
                <p tw="text-blue-800 font-light text-[1.125rem]">
                    {t("mobile_unavailable_description")}
                </p>
            </div>
        </section>
    </>
);

export default MobileUnavailable;

import { memo } from "react";
import { RelatedActiveFilter } from "types/custom-select-active-filter.types";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { languages, scenarios } from "utils/items";
import { CommonKeysPair } from "types/common-keys-pair.types";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const handleSelectOptions = (activeFilterType: string) => {
    const activeArray = activeFilterType === "scenario" ? scenarios : languages;
    return activeArray.map(({ id, name }: CommonKeysPair) => (
        <MenuItem key={id} value={id} tw="text-sm">
            {t(name)}
        </MenuItem>
    ));
};

const CustomSelect = memo(
    ({ activeFilter, activeFilterType }: RelatedActiveFilter) => {
        const { setActiveFilter } = useAfricanSocialProtection();

        const StyledSelect = styled(Select)<any>(() => ({
            ".MuiInputBase-input": {
                fontSize: theme`fontSize.sm`,
                color: theme`colors.blue.600`,
                paddingRight: "2.625rem !important",
                padding: "0.25rem",
                borderRadius: 0,
                borderBottom:
                    activeFilterType === "scenario"
                        ? `solid 1.5px ${theme`colors.blue.600`}`
                        : 0,
                display: "flex",
                alignItems: "center"
            },
            ".MuiSelect-icon": {
                color: theme`colors.blue.600`
            }
        }));

        const handleChange = ({ target }: SelectChangeEvent) => {
            if (target.name === "language") {
                localStorage.setItem("i18nextLng", target.value);
                window.location.reload();
            } else setActiveFilter({ name: target.name, value: target.value });
        };

        return (
            <StyledSelect
                value={activeFilter}
                onChange={handleChange}
                name={activeFilterType}
            >
                {handleSelectOptions(activeFilterType)}
            </StyledSelect>
        );
    }
);

export default CustomSelect;

import useAutoComplete from "hooks/useAutocomplete";
import ClearIcon from "assets/images/ClearIcon";
import countries from "data/AfricaCountrieflags.json";
import subregions from "data/AfricaSubregions.json";
import { t } from "i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { AutocompleteType } from "types/autocomplete.types";
import TextField from "@mui/material/TextField";
import { AutoCompleteStyles } from "utils/autoComplete";
import { AutoCompleteComparedOptionStyles } from "utils/comparedCountryAutocomplete";
import { Country } from "types/country.types";
import { handleSort } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const CountryAutoComplete = ({
    placeHolder,
    isComparisonCountry,
    value
}: AutocompleteType) => {
    const { handleAutocompleteChange } = useAutoComplete();
    const options = [
        ...handleSort(subregions[0].data),
        ...handleSort(countries[0].data)
    ];

    return (
        <Autocomplete
            clearIcon={<ClearIcon />}
            options={options}
            sx={AutoCompleteStyles}
            onChange={(e, _, reason) =>
                handleAutocompleteChange(e, isComparisonCountry, reason)
            }
            value={value}
            getOptionLabel={(option: Country) => t(option.name)}
            renderOption={(props: any, option: any) => (
                <button
                    {...props}
                    id={option.name}
                    data-iso3c={option.iso3c}
                    data-iso2c={option.iso2c}
                    style={AutoCompleteComparedOptionStyles}
                    css={[
                        option.id &&
                            tw`first-of-type:border-b first-of-type:border-b-blue-100 [&:nth-of-type(6)]:border-b [&:nth-of-type(6)]:border-b-blue-100`
                    ]}
                >
                    {option.file_url && (
                        <img
                            loading="lazy"
                            width="32"
                            src={option.file_url}
                            alt={option.name}
                        />
                    )}
                    {t(option.name)}
                </button>
            )}
            renderInput={(params: any) => {
                const placeholder = t(placeHolder);
                const flags = options.filter(
                    (option) => option.name === params.inputProps.value
                );

                return (
                    <div tw="flex items-center relative">
                        {flags.map((flag) => (
                            <img
                                key={flag.file_url}
                                loading="lazy"
                                width="24"
                                src={flag.file_url}
                                alt={flag.name}
                                tw="absolute left-[1rem]"
                            />
                        ))}
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                                placeholder
                            }}
                            variant="outlined"
                        />
                    </div>
                );
            }}
        />
    );
};

export default CountryAutoComplete;

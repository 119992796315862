import { theme } from "twin.macro";
import { useCallback, useEffect, useState } from "react";
import { urls } from "config/urls";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import useFetch from "hooks/useFetch";
import FlipNumbers from "react-flip-numbers";
import {
    flipNumberPerspective,
    secondsPerYear,
    beginningCurrentYear
} from "utils/utilities";
/** @jsxImportSource @emotion/react */

const styles = {
    color: theme`colors.blue.800`,
    fontSize: "6rem",
    fontWeight: 800,
    fontFamily: "Noto Sans"
};

const FlipNumber = ({ population }: { population: number }) => {
    const { scenario, year } = useAfricanSocialProtection();
    const { data, isLoading } = useFetch(
        `${urls.API_HOST_URL}/main-hero?year=${year + 1}&scenario=${scenario}`
    );
    const calculateCurrentPopulationCoverage = useCallback((): number => {
        const now = Math.round(Date.now() / 1000);
        const secondsUntilNow = now - beginningCurrentYear;
        const currentyearPopulationCoverageChange =
            data.population - population;
        const populatioCoveredPerSeconds =
            currentyearPopulationCoverageChange / secondsPerYear;
        const populatioCoveredPerSecondsUntilNow =
            secondsUntilNow * populatioCoveredPerSeconds;

        return populatioCoveredPerSecondsUntilNow;
    }, [data, population]);

    const currentCoverage = isLoading
        ? ""
        : calculateCurrentPopulationCoverage();
    const [tickingPopulationCoverage, setTickingPopulationCoverage] =
        useState(currentCoverage);

    useEffect(() => {
        const interval = setInterval(() => {
            setTickingPopulationCoverage(
                Math.floor(
                    population + calculateCurrentPopulationCoverage()
                ) as any
            );
        }, 1000);

        return () => clearInterval(interval);
    }, [scenario, year, population, data, calculateCurrentPopulationCoverage]);

    return (
        <FlipNumbers
            height={85}
            width={60}
            color={theme`colors.blue.800`}
            play
            perspective={flipNumberPerspective}
            numbers={tickingPopulationCoverage.toLocaleString()}
            numberStyle={styles}
            nonNumberStyle={styles}
        />
    );
};

export default FlipNumber;

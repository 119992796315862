import logo from "components/ErrorBoundary/assets/images/logo.svg";
import { t } from "i18next";
import arrowRight from "components/ErrorBoundary/assets/images/learnMoreArrow.svg";
import background from "components/ErrorBoundary/assets/images/404Bg.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ErrorFallback = () => (
    <section
        style={{ backgroundImage: `url(${background})` }}
        tw="m-0 bg-cover bg-center h-screen relative"
    >
        <nav tw="p-[0.5rem 1.5rem] uppercase flex items-center gap-[1rem]">
            <img src={logo} alt="Logo" />
            <h1 tw="text-[0.625rem] text-blue-600">
                {t("title")}
                <p tw="text-purple-400 text-[0.5rem]">{t("sub_title")}</p>
            </h1>
        </nav>
        <h2 tw="text-center text-blue-800 text-[12.5rem] leading-none font-bold">
            404
        </h2>
        <h3 tw="text-center text-blue-600 text-xxl font-semiBold pb-[1.8rem]">
            {t("page_not_found")}
        </h3>
        <p tw="text-center text-blue-800 text-sm font-light">
            {t("sorry_text")}
        </p>
        <button
            tw="absolute bottom-[13%] left-[50%] -translate-x-1/2 bg-blue-600 rounded-[0.4rem] text-white p-[0.6rem 1rem] flex w-[11.3rem]"
            onClick={() => window.location.reload()}
        >
            {t("back_to_homepage")}
        </button>
        <a
            tw="absolute bottom-[7%] left-[50%] -translate-x-1/2 uppercase text-blue-600 text-xs font-medium flex items-center max-w-[21rem] m-auto pl-[2.2rem]"
            href="/learn-more"
        >
            {t("learn_more_about_ASPC")}
            <img src={arrowRight} alt="Learn more" />
        </a>
    </section>
);

export default ErrorFallback;

import { t } from "i18next";
import tooltipArrow from "assets/images/tooltipArrow.svg";
import { TooltipInfo } from "types/tooltip.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Tooltip = ({ tooltipInfo }: TooltipInfo) => (
    <article
        tw="md:hidden bg-blue-100 rounded-[0.5rem] p-[0.5rem 1rem] shadow-[0 0.25rem 0.25rem rgba(37, 37, 37, 0.1);] w-full max-w-[22rem] fixed z-[1002] -translate-x-1/2"
        style={{
            left: `${tooltipInfo?.originalEvent?.clientX}px`,
            top: `${tooltipInfo?.originalEvent?.clientY}px`
        }}
    >
        <div tw="flex justify-between items-center pb-[1.5rem] text-blue-800">
            <div tw="flex items-start gap-[0.5rem] justify-between">
                <img
                    tw="pt-[0.3rem]"
                    width={32}
                    src={tooltipInfo?.target?.feature.file_url}
                    alt="Flag"
                />
                <h3 tw="font-semiBold text-xl">
                    {tooltipInfo?.target?.feature?.properties.name}
                </h3>
            </div>
            <div tw="text-xs flex gap-[0.5rem]">
                <h4 tw="font-light">{t("tooltip_coverage")}</h4>
                <span tw="font-medium">
                    {tooltipInfo?.percentage?.toFixed(2)}%
                </span>
            </div>
        </div>
        <div tw="pb-[1.688rem] flex flex-col gap-[0.5rem]">
            <div tw="flex font-semiBold  justify-between items-center text-blue-800 gap-[0.25rem]">
                <h4>{t("population")}</h4>
                <span tw="text-[1.125rem]">
                    {tooltipInfo?.populationTotal?.toLocaleString()}
                </span>
            </div>
            <div tw="text-xs text-blue-800 flex justify-between items-center font-light gap-[3rem]">
                <p>{t("tooltip_header_1")}</p>
                <span>
                    {tooltipInfo?.populationSocialProtectionCovered?.toLocaleString()}
                </span>
            </div>
        </div>
        <div tw="flex justify-end gap-[1rem] items-center text-blue-600 uppercase font-medium text-xs">
            {t("click_to_explore")}
            <img src={tooltipArrow} alt="Arrow right" />
        </div>
    </article>
);

export default Tooltip;

import useToggle from "hooks/useToggle";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { t } from "i18next";
import tooltipArrow from "assets/images/tooltipArrow.svg";
// import DynamicSvg from "components/DynamicSvg";
import { TooltipInfo } from "types/tooltip.types";
import subregionsArrow from "assets/images/map/subregionsArrow.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { scrollIntoView } from "utils/utilities";

const TooltipSubRegions = ({ ifForSubregions, data }: TooltipInfo) => {
    const { activeMap, activeLegendCategory, mapData, setActiveFilter } =
        useAfricanSocialProtection();
    const { handleToggleState, toggleState } = useToggle();

    const selectedSubRegion = mapData.find(
        (item) => item.region === activeLegendCategory
    )?.subRegionInfo;

    return (
        <article
            css={[
                ifForSubregions && tw`absolute top-0 right-[-38%] left-[unset]`
            ]}
            tw="md:hidden bg-blue-100 rounded-[0.5rem] p-[0.5rem 1rem] shadow-[0 0.25rem 0.25rem rgba(37, 37, 37, 0.1);] w-full max-w-[22rem] fixed z-[1001] -translate-x-1/2"
        >
            <div tw="flex justify-between items-center text-blue-800">
                <div tw="flex items-start gap-[0.5rem] justify-between">
                    {selectedSubRegion?.file_url && (
                        <img
                            tw="pt-[0.3rem]"
                            width={32}
                            src={selectedSubRegion?.file_url}
                            alt="Flag"
                        />
                    )}
                    <h3 tw="font-semiBold text-xl max-w-[10rem]">
                        {t(data.recSummary.name)}
                    </h3>
                </div>
                <div tw="text-xs flex gap-[0.5rem]">
                    <h4 tw="font-light">{t("tooltip_coverage")}</h4>
                    <span tw="font-medium">
                        {data.recSummary.coverage.toFixed(2)}%
                    </span>
                </div>
            </div>
            <button
                onClick={() => handleToggleState("showSubRegionsCountries")}
                tw="text-blue-600 text-sm flex items-center gap-[0.5rem] py-[0.5rem]"
            >
                {t("subregions_countries")}
                <img
                    css={[
                        tw`rotate-180`,
                        toggleState.showSubRegionsCountries && tw`rotate-0`
                    ]}
                    alt="Arrow"
                    src={subregionsArrow}
                />
            </button>
            <div
                css={[
                    toggleState.showSubRegionsCountries
                        ? tw`text-xs text-blue-800 font-light`
                        : tw`hidden`
                ]}
            >
                {data.mapDatas.map(
                    ({ countryName }: { countryName: string }) => (
                        <span key={countryName}>{countryName}, </span>
                    )
                )}
            </div>
            <div tw="pb-[1.688rem] flex flex-col gap-[0.5rem]">
                <div tw="flex font-semiBold  justify-between items-center text-blue-800 gap-[0.25rem]">
                    <h4>{t("population")}</h4>
                    <span tw="text-[1.125rem]">
                        {data.recSummary.population.toLocaleString()}
                    </span>
                </div>
                <div tw="text-xs text-blue-800 flex justify-between items-center font-light gap-[3rem]">
                    <p>{t("tooltip_header_1")}</p>
                    <div tw="text-regular font-regular flex gap-[0.5rem]">
                        <span>
                            {data.recSummary.populationCovered.toLocaleString()}
                        </span>
                    </div>
                </div>
                {/* <div tw="text-xs text-blue-800 flex justify-between items-center font-light gap-[3rem]">
                <p>{t("tooltip_header_2")}</p>
                <div tw="text-regular font-regular flex gap-[0.5rem]">
                    <span>5,603,818</span>
                    <DynamicSvg iconName="failing" size={16} />
                </div>
            </div> */}
            </div>
            {activeMap === "subregions" && (
                <button
                    onClick={() => {
                        setActiveFilter({
                            name: "showCountrySection",
                            value: true
                        });
                        setActiveFilter({
                            value: activeLegendCategory,
                            name: "country"
                        });
                        setActiveFilter({
                            value: activeLegendCategory,
                            name: "countryId"
                        });
                        scrollIntoView();
                    }}
                    tw="flex justify-end ml-auto gap-[1rem] items-center text-blue-600 uppercase font-medium text-xs"
                >
                    {t("click_to_explore")}
                    <img src={tooltipArrow} alt="Arrow right" />
                </button>
            )}
        </article>
    );
};

export default TooltipSubRegions;

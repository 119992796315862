import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { t } from "i18next";
import logo from "assets/images/logo.svg";
import ILOLogo from "assets/images/heroSection/iloLogo.svg";
import Menu from "components/Header/Menu";
import CountryAutoComplete from "components/AutoComplete";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Header = () => {
    const { country, scenario, language } = useAfricanSocialProtection();

    return (
        <header
            tw="grid grid-cols-[repeat(3, minmax(0, 1fr))] justify-between items-center w-[100%] z-[1005] px-[2rem] fixed shadow-[0px 2px 2px 0px rgba(0, 0, 0, 0.05)] transition-all duration-500"
            css={[
                scenario === "BASE"
                    ? tw`bg-blue-100`
                    : scenario === "PESSIMISTIC"
                    ? tw`bg-[#C9B6C6]`
                    : tw`bg-blue-100`
            ]}
        >
            <div tw="flex items-center my-[0.5rem]">
                <div
                    tw="flex items-center gap-[1rem] w-[14rem] leading-[0.8rem]"
                    css={[language !== "eng" && tw`w-[15rem]`]}
                >
                    <img src={logo} alt="Logo" />
                    <h1 tw="text-[0.75rem]">
                        <span tw="uppercase text-blue-600 font-bold">
                            {t("title")}
                        </span>
                        <p tw="text-purple-400">{t("sub_title")}</p>
                    </h1>
                </div>
                <img src={ILOLogo} alt="ILO logo" width={150} />
            </div>
            <CountryAutoComplete
                isComparisonCountry={false}
                placeHolder={t("country_profile")}
                value={country ? { name: country } : (null as any)}
            />
            <Menu />
        </header>
    );
};

export default Header;

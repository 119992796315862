import { urls } from "config/urls";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import useFetch from "hooks/useFetch";
import useCountdown from "hooks/useCountdown";
import YearFilter from "components/YearFilter";
import { t } from "i18next";
import FlipNumber from "components/FlipNumber";
import Plaster from "components/Main/HeroSection/Plaster";
import info from "assets/images/info.svg";
import CustomSelect from "components/Select";
import { bgs } from "utils/backgrounds";
import walkingPeopleRight from "assets/images/heroSection/walkingPeopleRight.gif";
import walkingPeopleLeft from "assets/images/heroSection/walkingPeopleLeft.gif";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const HeroSection = () => {
    const { scenario, year } = useAfricanSocialProtection();
    const [{ years, months, days }] = useCountdown();
    const { data } = useFetch(
        `${urls.API_HOST_URL}/main-hero?year=${year}&scenario=${scenario}`
    );

    return (
        <section
            id="home"
            tw="h-[100vh] relative flex items-center flex-col bg-no-repeat bg-cover transition-all duration-500 justify-center"
            style={{
                backgroundImage: `url(${bgs[scenario]})`
            }}
        >
            <h2 tw="pt-[5vh] flex items-center gap-[0.5rem] text-[1.875rem] text-blue-600">
                {t("hero_header_1")}
                <strong tw="text-xxl text-blue-800">{year}</strong>
                <div className="tooltip">
                    <img src={info} alt="Info" />
                    <span
                        className="tooltip-text"
                        tw="w-[17rem] bottom-[1.5rem] left-[1.5rem]"
                    >
                        {t("hero_section_info")}
                    </span>
                </div>
            </h2>
            <div tw="flex items-center gap-[1rem] h-[110px]">
                <img width={155} src={walkingPeopleLeft} alt="Walking people" />
                {data && <FlipNumber population={data.population} />}
                <img
                    width={155}
                    src={walkingPeopleRight}
                    alt="Walking people"
                />
            </div>
            <h3 tw="text-purple-800 text-lg flex items-center gap-[0.5rem]">
                <strong>{data && `${data?.percentage.toFixed(2)}%`}</strong>
                of Africa’s population
            </h3>
            {data && <Plaster percentage={data.percentage} />}
            <div tw="flex items-center justify-between gap-[0.75rem]">
                <h3 tw="font-semiBold text-sm text-blue-800">
                    {t("scenario")}
                </h3>
                <CustomSelect
                    activeFilter={scenario}
                    activeFilterType="scenario"
                />
                <div className="tooltip" tw="flex">
                    <img src={info} alt="Info" tw="max-w-[2rem]" />
                    <span
                        className="tooltip-text"
                        tw="w-[31.2rem] bottom-[1.5rem] left-[1.5rem]"
                    >
                        {scenario === "usual"
                            ? t("usual")
                            : scenario === "achieve"
                            ? t("achieve")
                            : t("pesimistic")}
                    </span>
                </div>
            </div>
            <div tw="text-xl text-blue-800 flex items-center gap-[0.5rem] flex-col py-[1rem]">
                <h3 tw="text-blue-600 font-bold text-lg">{t("hero_header")}</h3>
                <div tw="flex items-center gap-[0.5rem] text-center">
                    <span tw="flex flex-col">
                        <strong tw="text-xxl"> {years} :</strong>
                        <span tw="text-xs">
                            {months <= 1 ? "year" : "years"}
                        </span>
                    </span>
                    <span tw="flex flex-col">
                        <strong tw="text-xxl"> {months} :</strong>
                        <span tw="text-xs">
                            {t(months <= 1 ? "month" : "months")}
                        </span>
                    </span>
                    <span tw="flex flex-col">
                        <strong tw="text-xxl"> {days}</strong>
                        <span tw="text-xs">
                            {t(days <= 1 ? "day" : "days")}
                        </span>
                    </span>
                </div>
            </div>
            <YearFilter isFromMap={false} isFromHeroSection={true} />
        </section>
    );
};

export default HeroSection;

import { LegendProps } from "types/legend.types";
import { theme } from "twin.macro";

export const legendTitleItems: LegendProps[] = [
    {
        name: "countries",
        data: [
            {
                color: theme`colors.green.500`,
                id: "eightyToHundred",
                value: "80-100"
            },
            {
                color: theme`colors.green.300`,
                id: "sixityToEighty",
                value: "60-80"
            },
            {
                color: theme`colors.blue.800`,
                id: "fortyToSixity",
                value: "40-60"
            },
            {
                color: theme`colors.blue.400`,
                id: "twentyToForty",
                value: "20-40"
            },
            {
                color: theme`colors.yellow.200`,
                id: "smallerThanTwenty",
                value: "< 20%",
                name: "0-20"
            },
            {
                color: theme`colors.white`,
                id: "noData",
                value: "No data",
                name: "0-0"
            }
        ]
    },
    {
        name: "subregions",
        data: [
            {
                id: "allSubregions",
                value: "all_subregions"
            },
            {
                id: "northernAfrica",
                value: "NORTHERN_AFRICA"
            },
            {
                id: "westernAfrica",
                value: "WESTERN_AFRICA"
            },
            {
                id: "centralAfrica",
                value: "CENTRAL_AFRICA"
            },
            {
                id: "easternAfrica",
                value: "EASTERN_AFRICA"
            },
            {
                id: "southernAfrica",
                value: "SOUTHERN_AFRICA"
            }
        ]
    },
    {
        name: "recs",
        data: [
            {
                id: "cenSad",
                value: "CEN_SAD"
            },
            {
                id: "COMESA",
                value: "COMESA"
            },
            {
                id: "EAC",
                value: "EAC"
            },
            {
                id: "ECCAS",
                value: "ECCAS"
            },
            {
                id: "ECOWAS",
                value: "ECOWAS"
            },
            {
                id: "IGAD",
                value: "IGAD"
            },
            {
                id: "SADC",
                value: "SADC"
            },
            {
                id: "UMA",
                value: "UMA"
            }
        ]
    }
];

import { Country } from "types/country.types";

export const scrollIntoView = () =>
    document.querySelector("main")!.scrollIntoView({
        behavior: "smooth"
    });

export const breakPoint = 1024;

export const flipNumberPerspective = 100_000;

export const targetDate = new Date("2025-12-31T23:59:59");

export const handleSort = (array: Country[]) =>
    array.sort((countryA: Country, countryB: Country) =>
        countryA.name.localeCompare(countryB.name)
    );

export const currentYear = new Date().getFullYear();
export const secondsPerYear = 31556926;
export const beginningCurrentYear =
    new Date(currentYear, 0, 0).getTime() / 1000;

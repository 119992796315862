import { t } from "i18next";
import logo from "assets/images/footer/footerLogo.svg";
import ContactUs from "components/Footer/ContactUs";
import Supporter from "components/Footer/Supporter";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Footer = () => (
    <footer>
        <div tw="bg-blue-100 text-blue-600 p-[2rem] flex flex-wrap items-end gap-[2rem]">
            <div>
                <h2 tw="max-w-[20rem] font-bold text-xxl uppercase">
                    {t("title")}
                </h2>
                <span>{t("footer_by")}</span>
                <div tw="gap-[0 1rem] w-[13rem] uppercase flex justify-between items-center pt-[0.5rem]">
                    <img alt="WDL" src={logo} />
                    <span>{t("footer_world_data_lab")}</span>
                </div>
            </div>
            <ContactUs />
            <Supporter />
        </div>
    </footer>
);

export default Footer;

import { t } from "i18next";
import aboutUs from "assets/images/aboutUs/aboutUs.svg";
import methodology from "assets/images/aboutUs/methodology.svg";
import useToggle from "hooks/useToggle";
import aboutUsBg from "assets/images/aboutUs/aboutUsBg.svg";
import downloadMethodology from "assets/images/downloadMethodology.svg";
import arrowDropdown from "assets/images/arrowDropdown.svg";
import arrow from "assets/images/arrowContact.svg";
import ILO from "assets/images/ILO.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const AboutUsSection = () => {
    const { handleToggleState, toggleState } = useToggle();

    return (
        <section
            tw="h-[100vh] bg-cover bg-center p-[3rem 0 2.375rem 7rem]"
            id="about_us"
            style={{
                backgroundImage: `url(${aboutUsBg})`
            }}
        >
            <div tw="h-[calc(100vh - 12.5rem)] max-w-[73.75rem] m-auto grid grid-cols-[repeat(2, 1fr)] gap-[2rem]">
                <article>
                    <h2 tw="font-semiBold text-[1.75rem] text-blue-600 mb-[0.5rem] leading-[1.75rem]">
                        {toggleState.isMethodologyActive
                            ? t("methodology")
                            : t("about_us")}
                    </h2>
                    <p tw="font-light text-blue-800 text-xs m-[0.5rem 0 1rem]">
                        {t("contact_description")}
                    </p>
                    <div tw="flex gap-[2rem] text-blue-600 text-sm">
                        <button
                            type="button"
                            onClick={() =>
                                handleToggleState("isMethodologyActive")
                            }
                            css={[
                                tw`h-auto p-[0.5rem 1rem] rounded-[0.3rem] text-[0.875rem] border border-blue-600`,
                                !toggleState.isMethodologyActive &&
                                    tw`bg-blue-600 text-white`
                            ]}
                        >
                            {t("about_us_button")}
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                handleToggleState("isMethodologyActive")
                            }
                            css={[
                                tw`h-auto p-[0.5rem 1rem] rounded-[0.3rem] text-[0.875rem] border border-blue-600`,
                                toggleState.isMethodologyActive &&
                                    tw`bg-blue-600 text-white`
                            ]}
                        >
                            {t("methodology")}
                        </button>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            tw="flex items-center gap-[0.5rem] h-auto p-[0.5rem 1rem] rounded-[0.3rem] text-[0.875rem] border border-blue-600"
                            href="https://www.ilo.org/africa/information-resources/publications/WCMS_828423/lang--en/index.htm"
                        >
                            <img src={ILO} alt="ILO anchor" />
                            Regional Strategy
                        </a>
                    </div>
                    <p tw="font-light my-[1rem] text-[1rem] text-blue-800">
                        {t(
                            toggleState.isMethodologyActive
                                ? "methodology_description"
                                : "about_us_description"
                        )}
                    </p>
                    {!toggleState.isMethodologyActive && (
                        <ul tw="text-sm text-blue-800 font-light pl-[2rem]">
                            <li>- {t("about_us_scenario_1")}</li>
                            <li>- {t("about_us_scenario_2")}</li>
                            <li>- {t("about_us_scenario_3")}</li>
                        </ul>
                    )}
                    {toggleState.isMethodologyActive && (
                        <div tw="mt-[2rem] flex items-center gap-[2rem] text-blue-600 text-sm">
                            {/* <a
                                target="_blank"
                                href="data/2023_12_12_Methodology_report.pdf"
                                tw="border border-blue-600 p-[0.5rem 1rem] rounded-[0.3rem] flex items-center gap-[1rem]"
                            >
                                <img alt="Download" src={downloadMethodology} />
                                {t("download_methodology")}
                            </a> */}
                            <div tw="border border-blue-600 p-[0.5rem 1rem] rounded-[0.3rem] flex items-center gap-[1rem] max-w-[18rem]">
                                <img alt="Download" src={downloadMethodology} />
                                Methodology PDF under review, will be published
                                soon
                            </div>
                            <a
                                target="_blank"
                                href="Methodology/index.html"
                                tw="font-medium text-xs uppercase flex items-center gap-[1rem]"
                            >
                                {t("learn_more")}
                                <img
                                    width={25}
                                    tw="-rotate-90"
                                    alt="Arrow"
                                    src={arrowDropdown}
                                />
                            </a>
                        </div>
                    )}
                    {!toggleState.isMethodologyActive && (
                        <div tw="mt-[1rem] font-light text-blue-800 flex items-center gap-[1rem]">
                            <img alt="Arrow" src={arrow} />
                            <p>
                                {t("contact_description_1")}{" "}
                                <a
                                    href="https://www.social-protection.org/gimi/ShowMainPage.action"
                                    tw="text-blue-600 font-medium text-xs uppercase flex items-center gap-[1rem]"
                                >
                                    {t("learn_more")}
                                    <img
                                        width={25}
                                        tw="-rotate-90"
                                        alt="Arrow"
                                        src={arrowDropdown}
                                    />
                                </a>
                            </p>
                        </div>
                    )}
                </article>
                <img
                    css={[
                        tw`transition-all duration-300 pt-[6rem]`,
                        !toggleState.isMethodologyActive &&
                            tw`ml-[-5rem] pt-[8rem]`
                    ]}
                    alt="About Us"
                    src={
                        toggleState.isMethodologyActive ? methodology : aboutUs
                    }
                />
            </div>
        </section>
    );
};

export default AboutUsSection;

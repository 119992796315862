import { useState, useEffect } from "react";
import { breakPoint } from "utils/utilities";

const getIsMobile = () => window.innerWidth <= breakPoint;

const useViewport = () => {
    const [isMobile, setIsMobile] = useState(getIsMobile);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= breakPoint);

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    return { isMobile };
};

export default useViewport;

import { t } from "i18next";
import ilo from "assets/images/footer/iloLogo.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Supporter = () => (
    <div tw="text-blue-600 ml-[4rem] font-medium text-sm flex justify-end flex-col gap-[1rem]">
        <h4>{t("footer_partnership")}</h4>
        <img width={250} src={ilo} alt="International Labout Organization" />
    </div>
);

export default Supporter;

import { ChangeEvent } from "react";
import { t } from "i18next";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { legendTitleItems } from "utils/legend";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import info from "assets/images/info.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const RadioButtons = () => {
    const { activeMap, setActiveFilter } = useAfricanSocialProtection();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value === "subregions" &&
            setActiveFilter({
                name: "activeLegendCategory",
                value: "all_subregions"
            });
        setActiveFilter({
            name: "activeMap",
            value: event.target.value
        });
    };

    return (
        <article
            className="radio-btns"
            tw="rounded shadow-[0 0.25rem 0.25rem 0 rgba(37, 37, 37, 0.1)] max-w-[10rem] p-[1rem] flex justify-between items-end"
        >
            <RadioGroup
                tw="gap-[0.5rem]"
                aria-labelledby="map-type-radio-buttons"
                name="map-type-radio-buttons"
                value={activeMap}
                onChange={handleChange}
            >
                {legendTitleItems.map(({ name }) => (
                    <FormControlLabel
                        key={name}
                        value={name}
                        control={
                            <Radio
                                sx={{
                                    color: "#82AFDC",
                                    padding: 0,
                                    "&.Mui-checked": {
                                        color: "#1E2DBE"
                                    }
                                }}
                            />
                        }
                        label={t(name)}
                    />
                ))}
            </RadioGroup>
            <div className="tooltip">
                <img src={info} alt="Info" tw="max-w-[1rem]" />
                <div
                    className="tooltip-text"
                    tw="w-[24rem] bottom-[1.2rem] left-[1rem]"
                >
                    <ul>
                        <li>
                            <span tw="px-[0.3rem] font-bold">.</span>
                            <strong>{t("countries")} - </strong>
                            <span>{t("countries_info")}</span>
                        </li>
                        <li>
                            <span tw="px-[0.3rem] font-bold">.</span>
                            <strong>{t("subregions")} - </strong>
                            <span>{t("subregions_info_text")}</span>
                        </li>
                        <li>
                            <span tw="px-[0.3rem] font-bold">.</span>
                            <strong>{t("recs")} - </strong>
                            <span>{t("recs_info")}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </article>
    );
};

export default RadioButtons;

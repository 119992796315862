import { createContext, useContext, useReducer } from "react";
import { globalStateReducer } from "reducers/globalState";
import geosJsonCoords from "data/AfricaGeoJson.json";
import countriesFlag from "data/AfricaCountrieflags.json";
import subRegions from "data/AfricaSubregions.json";
import {
    ActiveFiltersDispatchProps,
    ChildrenProps,
    GlobalState
} from "types/african-social-protection.types";
import { currentYear } from "utils/utilities";

const initialState: GlobalState = {
    activeSlideIndex: 0,
    activePage: "home",
    activeMap: "countries",
    activeLegendCategory: "all_subregions",
    language: localStorage.getItem("i18nextLng") || "eng",
    mapData: [],
    country: "",
    countryId: "",
    countryIdILO: "",
    countrySection: "overview",
    comparisonCountry: "",
    comparisonCountryId: "",
    year: currentYear,
    scenario: "BASE",
    setActiveFilter: () => {},
    showCountrySection: false
};

const handleReduceDataIds = (array: {}[], handleId: (elem: any) => string) =>
    array.reduce((obj: any, item: any) => {
        obj[handleId(item)] = item;
        return obj;
    }, {});

const geoJsonIds = handleReduceDataIds(
    geosJsonCoords[0].features,
    (item) => item.iso3c
);
const flagsIds = handleReduceDataIds(
    countriesFlag[0].data,
    (item) => item.iso3c
);
const ids = [...Object.keys(geoJsonIds)];
const newSetIds: any = new Set(ids);
initialState.mapData = [...newSetIds].map((id) => ({
    ...geoJsonIds[id],
    ...flagsIds[id]
}));

initialState.mapData = initialState.mapData.map((item) => {
    const findSubRegionInfo = subRegions[0].data.find(
        (subregioninfo) => subregioninfo.region === item.region
    );

    return { ...item, subRegionInfo: findSubRegionInfo };
});

const AfricanSocialProtectionContext = createContext(initialState);

export const AfricanSocialProtectionContextProvider = ({
    children
}: ChildrenProps) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);

    const setActiveFilter = ({ name, value }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { name, value },
            type: "SET_ACTIVE_FILTERS"
        });

    const value = {
        activeSlideIndex: state.activeSlideIndex,
        activeMap: state.activeMap,
        activeLegendCategory: state.activeLegendCategory,
        activePage: state.activePage,
        language: state.language,
        country: state.country,
        countryId: state.countryId,
        countryIdILO: state.countryIdILO,
        countrySection: state.countrySection,
        comparisonCountry: state.comparisonCountry,
        comparisonCountryId: state.comparisonCountryId,
        mapData: state.mapData,
        year: state.year,
        scenario: state.scenario,
        setActiveFilter,
        showCountrySection: state.showCountrySection
    };

    return (
        <AfricanSocialProtectionContext.Provider value={value}>
            {children}
        </AfricanSocialProtectionContext.Provider>
    );
};

export const useAfricanSocialProtection = () => {
    const context = useContext(AfricanSocialProtectionContext);
    if (context === undefined)
        throw new Error(
            "useAfricanSocialProtection needs to be within the AfricanSocialProtectionContext"
        );

    return context;
};

import { useCallback, useState, useEffect } from "react";
import { targetDate } from "utils/utilities";

const useCountdown = () => {
    const calculateTimeRemaining = useCallback(() => {
        const currentDate = new Date();
        const timeDifferenceMilliseconds: number =
            targetDate.getTime() - currentDate.getTime();
        const years = Math.floor(
            timeDifferenceMilliseconds / (365 * 24 * 60 * 60 * 1000)
        );
        const months = Math.floor(
            (timeDifferenceMilliseconds % (365 * 24 * 60 * 60 * 1000)) /
                (30.44 * 24 * 60 * 60 * 1000)
        );
        const days = Math.floor(
            (timeDifferenceMilliseconds % (30.44 * 24 * 60 * 60 * 1000)) /
                (24 * 60 * 60 * 1000)
        );

        return { years, months, days };
    }, []);

    const [timeRemaining, setTimeRemaining] = useState(
        calculateTimeRemaining()
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [calculateTimeRemaining]);

    return [timeRemaining];
};

export default useCountdown;

import { t } from "i18next";
import facebook from "assets/images/footer/facebookIcon.svg";
import globe from "assets/images/footer/globeIcon.svg";
import instagram from "assets/images/footer/instaIcon.svg";
import linkedIn from "assets/images/footer/linkedInIcon.svg";
import twitter from "assets/images/footer/twitterIcon.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ContactUs = () => (
    <div>
        <h3 tw="pb-[1rem] text-sm">{t("footer_contact_us")}</h3>
        <a href="mailto:aspc@worlddata.io">aspc@worlddata.io</a>
        <ul tw="flex justify-between gap-[1rem] pt-[0.9rem] max-w-[20rem]">
            <li>
                <a href="https://worlddata.io/">
                    <img src={globe} alt="Website" />
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/world-data-lab">
                    <img src={linkedIn} alt="LinkedIn" />
                </a>
            </li>
            <li>
                <a href="https://twitter.com/worlddatalab">
                    <img src={twitter} alt="Twitter" />
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/worlddatalab/">
                    <img src={facebook} alt="Facebook" />
                </a>
            </li>
            <li>
                <a href="https://instagram.com/worlddatalab">
                    <img src={instagram} alt="Instagram" />
                </a>
            </li>
        </ul>
    </div>
);

export default ContactUs;

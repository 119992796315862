import { ChangeEvent } from "react";
import { t } from "i18next";
import { legendTitleItems } from "utils/legend";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import info from "assets/images/info.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Legend = () => {
    const { activeMap, activeLegendCategory, setActiveFilter } =
        useAfricanSocialProtection();

    const activeLegend = legendTitleItems.filter(
        (legend) => legend.name === activeMap
    )[0];

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        setActiveFilter({
            name: "activeLegendCategory",
            value: event.target.value
        });

    return (
        <div
            css={[
                tw`shadow-[0 0.25rem 0.25rem rgba(37, 37, 37, 0.1)] text-xs max-w-[10rem] rounded p-[1rem] flex flex-col gap-[0.5rem] bg-white`,
                activeMap === "countries" && tw`max-w-[11.5rem]`,
                activeMap === "subregions" && tw`mb-[4rem]`
            ]}
        >
            <div tw="flex items-start justify-between text-blue-800">
                <h4>
                    {t(
                        activeMap === "countries"
                            ? "countries_legend_title"
                            : activeMap
                    )}
                </h4>
                <div className="tooltip">
                    <img src={info} alt="Info" tw="max-w-[1rem]" />
                    <div
                        className="tooltip-text"
                        css={[
                            tw`w-[16.825rem] bottom-[-10rem] left-[1.5rem]`,
                            activeMap !== "countries" &&
                                tw`bottom-[-6.5rem] left-[2.5rem]`
                        ]}
                    >
                        {activeMap === "countries" ? (
                            <>
                                <p>{t("countries_legend_info1")}</p>
                                <ul>
                                    <li>
                                        <span tw="px-[0.3rem] font-bold">
                                            .
                                        </span>
                                        {t("countries_legend_info2")}
                                    </li>
                                    <li>
                                        <span tw="px-[0.3rem] font-bold">
                                            .
                                        </span>
                                        {t("countries_legend_info3")}
                                    </li>
                                </ul>
                            </>
                        ) : (
                            <p>{t("subregions_info")}</p>
                        )}
                    </div>
                </div>
            </div>
            <div tw="flex justify-between gap-[0 1rem]">
                <RadioGroup
                    tw="flex gap-[0.5rem] items-center justify-between w-full"
                    aria-labelledby="map-type-radio-buttons"
                    name="map-type-radio-buttons"
                    value={activeLegendCategory}
                    onChange={handleChange}
                >
                    {activeLegend.data.map(({ id, value, color, name }) => (
                        <div
                            className="radioLegendBtns"
                            key={id}
                            tw="flex items-center w-full"
                        >
                            {id !== "noData" && (
                                <FormControlLabel
                                    key={value}
                                    value={name || value}
                                    label={
                                        activeMap !== "countries" && t(value)
                                    }
                                    control={
                                        <Radio
                                            sx={{
                                                color: "#82AFDC",
                                                padding: 0,
                                                "&.Mui-checked": {
                                                    color: "#1E2DBE"
                                                }
                                            }}
                                        />
                                    }
                                />
                            )}
                            {activeMap === "countries" && (
                                <div tw="w-[13rem] flex">
                                    <span
                                        style={{ backgroundColor: color! }}
                                        css={[
                                            tw`w-[1rem] h-[1rem] rounded-[50%] block`,
                                            id === "noData" &&
                                                tw`border border-blue-800 ml-[2.3rem]`
                                        ]}
                                    ></span>
                                    <p tw="text-sm text-blue-800 font-semiBold pl-[0.5rem]">
                                        {t(value)}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </RadioGroup>
            </div>
        </div>
    );
};

export default Legend;

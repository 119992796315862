import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { t } from "i18next";
import arrowGoal from "assets/images/arrowGoal.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Plaster = ({ percentage }: { percentage: number }) => {
    const { scenario } = useAfricanSocialProtection();

    const handleSeparators = () => {
        const dividers = 5;

        return [...Array(dividers).keys()].map((_, i: number) => (
            <span
                key={i}
                style={{ left: `${100 * (i / dividers)}%` }}
                css={[
                    tw`absolute border-dashed border-r-[0.19rem] border-r-blue-800 h-full`,
                    i === 0 && tw`hidden`,
                    scenario === "PESSIMISTIC" && tw`border-r-white`
                ]}
            ></span>
        ));
    };

    return (
        <div tw="relative">
            <span
                tw="z-10 rounded-[2.8rem] h-[2.25rem] absolute top-[2.75rem] transition-all duration-300"
                style={{
                    width: `${percentage + 10}%`,
                    background:
                        "linear-gradient(264deg, #230050 15.38%, rgba(30, 45, 190, 0.67) 115.3%)"
                }}
            ></span>
            <span
                tw="z-10 absolute text-white w-[3.7rem] h-[3.7rem] block bg-blue-800 rounded-[50%] text-[0.6rem] text-center top-[2rem] p-[0.25rem] transition-all duration-300"
                style={{
                    left: `${percentage}%`
                }}
            >
                <strong tw="font-bold text-sm">{`${Math.round(
                    percentage
                )}%`}</strong>
                <br />
                {t(`current_${scenario}`)}
            </span>
            <div
                tw="py-[0.15rem] m-[2.5rem 0 1rem] w-[34.3rem] h-[2.7rem]"
                style={{
                    borderTop: "1px solid",
                    borderBottom: "1px solid",
                    borderImage:
                        "linear-gradient(to right, transparent 10%, rgba(130, 175, 220, 0.7) 25%, rgba(130, 175, 220, 0.7) 75%, transparent 95%) 67"
                }}
            >
                <div
                    tw="relative h-[2.25rem] rounded-[2.5rem] flex"
                    style={{
                        background:
                            scenario === "OPTIMISTIC"
                                ? "linear-gradient(310deg, #70B450 14.17%, rgba(140, 225, 100, 0.25) 70.2%)"
                                : scenario === "BASE"
                                ? "linear-gradient(270deg, #FAB429 15.89%, #E49428 82.59%, rgba(250, 180, 40, 0.00) 96.64%), linear-gradient(270deg, rgba(112, 180, 80, 0.55) 0%, #70B450 8.3%, #84B855 26.74%, #FFD176 78.3%, rgba(255, 255, 255, 0.00) 129%)"
                                : "linear-gradient(270deg, #FB636F 19.86%, #E31E25 77.88%, rgba(227, 30, 37, 0.00) 125.63%)"
                    }}
                >
                    <span
                        tw="absolute text-blue-800 top-[-1.6rem] flex items-center gap-[0.5rem]"
                        style={{ left: `40%` }}
                    >
                        <img alt="Arrow" src={arrowGoal} />
                        <strong tw="text-xs">40%</strong>
                        <strong tw="text-[0.6rem]">
                            {t("our_goal_by_2025")}
                        </strong>
                    </span>
                    {handleSeparators()}
                </div>
            </div>
        </div>
    );
};

export default Plaster;

import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import Loader from "components/Loader";
import { useEffect, useRef, useState } from "react";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import YearFilter from "components/YearFilter";
import useMarkers from "hooks/useMarkers";
import { LeafletEvent, GeoJSON as LeafletGeoJSON } from "leaflet";
import { countryStyle, centerMap, defaultMapZoom } from "utils/leaflet";
import { GeoJSON, MapContainer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Legend from "components/Main/MapSection/Legend";
import Tooltip from "components/Main/MapSection/Tooltip";
import TooltipSubRegions from "components/Main/MapSection/TooltipSubRegions";
import CustomSelect from "components/Select";
import { t } from "i18next";
import useLeaflet from "hooks/useLeaflet";
import { TooltipInfo } from "types/tooltip.types";
import { scrollIntoView } from "utils/utilities";
import RadioButtons from "components/Main/MapSection/RadioButtons";
import sea from "assets/images/sea.svg";
import info from "assets/images/info.svg";
import subregionsLegend from "assets/images/map/subregionsLegend.svg";
import recsLegend from "assets/images/map/recsLegend.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const MapSection = () => {
    const {
        activeMap,
        activeLegendCategory,
        mapData,
        scenario,
        setActiveFilter,
        year
    } = useAfricanSocialProtection();
    const [mapRef, setMapRef] = useState<LeafletEvent | null>(null);
    const geoJsonLayerRef = useRef<LeafletGeoJSON | null>(null);
    const { handleResize } = useLeaflet(mapRef);
    const { handleMarkers } = useMarkers();
    const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | null>(null);
    const { data, isLoading } = useFetch(
        `${urls.API_HOST_URL}/map?year=${year}&scenario=${scenario}${
            activeMap === "subregions"
                ? `${
                      activeLegendCategory === "all_subregions"
                          ? ""
                          : `&subRegionFilter=${activeLegendCategory}`
                  }`
                : activeMap === "recs"
                ? `${
                      activeLegendCategory.includes("all_") ||
                      activeLegendCategory.includes("_AFRICA")
                          ? ""
                          : `&recFilter=${activeLegendCategory}`
                  }`
                : `${
                      activeLegendCategory.includes("all_") ||
                      activeLegendCategory.includes("_AFRICA")
                          ? ""
                          : `&populationCoverageFilter=${activeLegendCategory}`
                  }`
        }`
    );

    const updatedMapData = mapData.map((item) => {
        const findedItem = data?.mapDatas?.find(
            ({ countryCode }: { countryCode: string }) =>
                countryCode === item.iso3c
        );

        return { ...findedItem, ...item };
    });

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    useEffect(() => {
        if (activeMap !== "subregions") {
            if (tooltipInfo) {
                const targetsMap = Object.values(
                    mapRef?.target._targets
                ).filter(
                    ({ feature }: any) =>
                        feature?.iso3c === tooltipInfo.target.feature.iso3c
                );
                geoJsonLayerRef?.current?.setStyle({
                    fillOpacity: 0.1
                });
                targetsMap.forEach((target: any) =>
                    target.setStyle({
                        fillColor: target.options.fillColor,
                        fillOpacity: 1,
                        color: theme`colors.blue.800`,
                        weight: 0.04
                    })
                );
            } else {
                geoJsonLayerRef?.current?.setStyle({
                    fillOpacity: 1
                });
            }
        }
    }, [activeMap, tooltipInfo, mapRef]);

    useEffect(() => {
        if (
            activeMap === "subregions" &&
            activeLegendCategory !== "all_subregions"
        ) {
            const targetsMap = Object.values(mapRef?.target._targets).filter(
                ({ feature }: any) => feature?.region === activeLegendCategory
            );
            geoJsonLayerRef?.current?.setStyle({
                fillOpacity: 0
            });
            targetsMap.forEach((target: any) =>
                target.setStyle({
                    fillColor: target.options.fillColor,
                    fillOpacity: 1,
                    color: theme`colors.blue.800`,
                    weight: 0.04
                })
            );
        }
    }, [activeLegendCategory, activeMap, mapRef]);

    const highlightFeature = (layer: any) => {
        const highlightedItem = updatedMapData.find(
            ({ countryCode }: { countryCode: string }) =>
                countryCode === layer.target.feature.iso3c
        );
        layer = {
            ...layer,
            ...highlightedItem
        };
        layer.target.feature.name && setTooltipInfo(layer);
    };

    const resetHighlight = () => setTooltipInfo(null);

    const handleClick = ({ target }: any) => {
        if (target.feature.percentage) {
            setActiveFilter({
                name: "country",
                value: target.feature.properties.name
            });
            setActiveFilter({
                name: "countryId",
                value: target.feature.iso3c
            });
            setActiveFilter({
                value: target.feature.iso2c,
                name: "countryIdILO"
            });
            setActiveFilter({
                name: "showCountrySection",
                value: true
            });
            scrollIntoView();
        }
    };

    const mapInteractions = (_: any, layer: any) => {
        layer.on({
            mouseout: resetHighlight,
            mouseover: highlightFeature,
            click: handleClick
        });
    };

    return (
        <section
            id="map"
            tw="h-full grid grid-cols-[25% auto] items-center relative p-[3rem 0 2rem] mb-[5rem]"
        >
            <article tw="p-[0 4rem] z-[1004] w-[27rem]">
                <div tw="max-w-[15rem] flex flex-col gap-[0.35rem] pb-[2.125rem]">
                    <h2 tw="text-xl font-bold text-blue-700">
                        {t("map_header")}
                    </h2>
                    <p tw="text-xs font-light text-blue-800">
                        {t("map_description")}
                    </p>
                </div>
                <RadioButtons />
                <div tw="max-w-[15rem] flex flex-col gap-[0.5rem] m-[1.5rem 0]">
                    <div tw="flex items-center justify-between gap-[0.75rem]">
                        <h3 tw="font-semiBold text-sm text-blue-800">
                            {t("scenario")}
                        </h3>
                        <CustomSelect
                            activeFilter={scenario}
                            activeFilterType="scenario"
                        />
                        <div className="tooltip" tw="flex gap-[1.5rem]">
                            <img src={info} alt="Info" tw="max-w-[1rem]" />
                            <span
                                className="tooltip-text"
                                tw="w-[31.2rem] bottom-[1rem] left-[1rem]"
                            >
                                {scenario === "usual"
                                    ? t("usual")
                                    : scenario === "achieve"
                                    ? t("achieve")
                                    : t("pesimistic")}
                            </span>
                        </div>
                    </div>
                    <p tw="text-xs font-light text-blue-800">
                        {t("map_scenario_description")}
                    </p>
                </div>
                <Legend />
            </article>
            <article
                tw="w-full absolute flex items-center bg-contain bg-center left-1/2 -translate-x-1/2 max-w-[60rem] z-[1003]"
                style={{
                    backgroundImage: `url(${sea})`
                }}
            >
                <MapContainer
                    whenReady={setMapRef as any}
                    tw="h-[36.625rem] w-full relative flex items-center justify-center"
                    center={centerMap}
                    zoom={defaultMapZoom}
                    minZoom={defaultMapZoom}
                    maxZoom={5}
                    zoomControl={false}
                    doubleClickZoom={false}
                    trackResize={false}
                    touchZoom={false}
                    tap={false}
                    scrollWheelZoom={false}
                >
                    <ZoomControl />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <GeoJSON
                            ref={geoJsonLayerRef}
                            data={updatedMapData as any}
                            style={(country: any) =>
                                activeLegendCategory === "all_subregions" ||
                                activeMap !== "subregions"
                                    ? countryStyle(country, activeMap)
                                    : activeMap === "subregions" &&
                                      country?.region === activeLegendCategory
                                    ? countryStyle(country, activeMap)
                                    : {
                                          fillColor: "white",
                                          fillOpacity: 1,
                                          weight: 0.04,
                                          color: theme`colors.blue.800`
                                      }
                            }
                            onEachFeature={mapInteractions}
                        />
                    )}
                    {handleMarkers()}
                </MapContainer>
                <p
                    id="disclaimer"
                    tw="absolute bottom-[-1rem] left-[50%] -translate-x-1/2 text-[0.55rem] text-blue-800 z-[1002] w-full max-w-[41.875rem] bg-white p-[0.5rem 1rem] rounded"
                >
                    {t("map_borders")} <br />
                    {t("map_borders_before_link")}{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        tw="text-blue-600"
                        href="https://www.un.org/geospatial/content/africa-2"
                    >
                        {t("map_borders_link")}
                    </a>
                </p>
                <img
                    css={[
                        tw`absolute bottom-[-6rem] left-[50%] -translate-x-1/2`,
                        activeMap === "countries" && tw`hidden`
                    ]}
                    alt="Map legend"
                    src={
                        activeMap === "subregions"
                            ? subregionsLegend
                            : recsLegend
                    }
                />
                {tooltipInfo && <Tooltip tooltipInfo={tooltipInfo as any} />}
                {activeMap !== "countries" &&
                    activeLegendCategory !== "all_subregions" &&
                    data.recSummary && (
                        <TooltipSubRegions ifForSubregions={true} data={data} />
                    )}
            </article>
            <YearFilter isFromMap={true} />
        </section>
    );
};

export default MapSection;

import useToggle from "hooks/useToggle";
import { t } from "i18next";
import SideBar from "components/ShareSocials";
import Header from "components/Header";
import HeroSection from "components/Main/HeroSection";
import MapSection from "components/Main/MapSection";
import AboutUsSection from "components/Main/AboutUsSection";
import Footer from "components/Footer";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import Country from "components/Main/CountrySection";
import MobileUnavailable from "components/MobileUnavailable";
import useViewport from "hooks/useViewport";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const AfricaProtection = () => {
    const { showCountrySection } = useAfricanSocialProtection();
    const {
        handleToggleState,
        toggleState: { isShareBtnClicked }
    } = useToggle();
    const { isMobile } = useViewport();

    return (
        <>
            {isMobile ? (
                <MobileUnavailable />
            ) : (
                <>
                    <Header />
                    <main tw="relative z-[1004]">
                        {showCountrySection ? (
                            <section tw="h-full min-h-[35rem] p-[4.3rem 0 3rem]">
                                <Country />
                            </section>
                        ) : (
                            <>
                                <HeroSection />
                                <MapSection />
                                <AboutUsSection />
                                <button
                                    onClick={() =>
                                        handleToggleState("isShareBtnClicked")
                                    }
                                    tw="text-[0.9rem] text-blue-800 bg-white shadow rounded-[0.3rem] px-[1rem] py-[0.3rem] border border-blue-800 w-[5rem] fixed bottom-[5rem] right-[2rem]"
                                >
                                    {t("share_button")}
                                </button>
                                {isShareBtnClicked && <SideBar />}
                            </>
                        )}
                    </main>
                    <Footer />
                </>
            )}
        </>
    );
};

export default AfricaProtection;

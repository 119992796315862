import markers from "data/Markers.json";
import { t } from "i18next";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";

const handlePinIcon = (icon: string) =>
    L.icon({
        iconSize: [16, 16],
        iconUrl: icon
    });

const useMarkers = () => {
    const handleMarkers = () =>
        markers[0].data.map((marker) => (
            <Marker
                key={marker.location.lat}
                position={marker.location}
                icon={handlePinIcon(marker.icon)}
                eventHandlers={{
                    click: () => {
                        document.querySelector("#disclaimer")!.scrollIntoView({
                            behavior: "smooth",
                            inline: "nearest",
                            block: "center"
                        });
                    },
                    mouseout: (e) => e.target.closePopup(),
                    mouseover: (e) => e.target.openPopup()
                }}
            >
                <Popup>
                    <p>{t("map_borders")}</p>
                </Popup>
            </Marker>
        ));

    return { handleMarkers };
};

export default useMarkers;

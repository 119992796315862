// import twitter from "assets/images/sidebar/twitter.svg";
import facebook from "assets/images/sidebar/facebook.svg";
import linkedIn from "assets/images/sidebar/linkedIn.svg";
import email from "assets/images/sidebar/email.svg";
import whatsapp from "assets/images/sidebar/whatsapp.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const SideBar = () => (
    <aside tw="py-[0.3rem] border border-blue-800 bg-white shadow w-[3rem] fixed bottom-[8rem] right-[2rem] rounded-[0.3rem] flex flex-col gap-[1rem] items-center">
        {/* <img
            tw="cursor-pointer opacity-70 hover:opacity-100"
            // target="popup"
            onClick={() =>
                window.open(
                    // "https://twitter.com/intent/tweet?url=https://africasocialprotection.io&text=Africa%20Social%20Protection%20 Clock,%20 monitor%20the%20 progress%20of%20the%20Africa%20Regional%20working%20 towards%20the%2040%%20target&via="
                    "https://twitter.com/intent/tweet?url=https://africasocialprotection.io&text=Africa%20Social%20Protection%20Clock,%20monitor%20the%20progress%20of%20the%20Africa%20Regional%20working%20towards%20the%2040%%20target&via=your_twitter_handle"
                )
            }
            src={twitter}
            alt="twitter"
        /> */}
        <img
            tw="cursor-pointer opacity-75 hover:opacity-100"
            // target="popup"
            onClick={() =>
                window.open(
                    "https://www.facebook.com/sharer.php?u=https://africasocialprotection.io/"
                )
            }
            src={facebook}
            alt="facebook"
        />
        <img
            tw="cursor-pointer opacity-75 hover:opacity-100"
            onClick={() =>
                window.open(
                    "https://www.linkedin.com/shareArticle?url=https://africasocialprotection.io&title=Africa%20Social%20Protection%20Clock,%20monitor%20the%20progress%20of%20the%20Africa%20Regional%20working%20towards%20the%2040%%20target"
                )
            }
            src={linkedIn}
            alt="linkedIn"
        />
        <a href="mailto:?subject=Africa%20Social%20Protection%20Clock,%20monitor%20the%20progress%20of%20the%20Africa%20Regional%20working%20towards%20the%2040%%20target&body=https:%2F%2Fafricasocialprotection.io%2F">
            <img
                tw="cursor-pointer opacity-75 hover:opacity-100"
                src={email}
                alt="email"
            />
        </a>
        {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
        ) && (
            <img
                tw="cursor-pointer opacity-75 hover:opacity-100"
                // target="popup"
                onClick={() =>
                    window.open(
                        "https://api.whatsapp.com/send?text=Africa Social Protection, monitor the progress of the Africa Regional working towards the 40% target of the https://africasocialprotection.io/",
                        "Popup",
                        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=680, height=400, top=30"
                    )
                }
                src={whatsapp}
                alt="whatsapp"
            />
        )}
    </aside>
);

export default SideBar;

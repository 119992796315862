import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { Suspense, lazy } from "react";

const countrySections: any = {
    overview: lazy(() => import("components/Main/CountrySection/Overview")),
    trends: lazy(() => import("components/Main/CountrySection/Trends")),
    comparison: lazy(() => import("components/Main/CountrySection/Comparison"))
};

const Country = () => {
    const { countrySection } = useAfricanSocialProtection();
    const Component = countrySections[countrySection];

    return (
        <Suspense fallback="Loading...">
            <Component />
        </Suspense>
    );
};

export default Country;

// import globe from "assets/images/languageIcon.svg";
import { links } from "utils/items";
// import CustomSelect from "components/Select";
import { useAfricanSocialProtection } from "context/africanSocialProtection";
import { useTranslation } from "react-i18next";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const HeaderMenu = () => {
    const { setActiveFilter, activePage } = useAfricanSocialProtection();
    const { t } = useTranslation();

    return (
        <nav tw="flex justify-end items-center gap-[2rem]">
            <ul tw="list-none flex justify-between items-center text-sm text-blue-600">
                {links.map(({ id }) => (
                    <li key={id}>
                        <a
                            onClick={() => {
                                setActiveFilter({
                                    name: "activePage",
                                    value: id
                                });
                                setActiveFilter({
                                    name: "showCountrySection",
                                    value: false
                                });
                                setActiveFilter({
                                    name: "country",
                                    value: ""
                                });
                                setActiveFilter({
                                    name: "comparisonCountry",
                                    value: ""
                                });
                                setActiveFilter({
                                    name: "countrySection",
                                    value: "overview"
                                });
                                setActiveFilter({
                                    name: "activeMap",
                                    value: "countries"
                                });
                            }}
                            css={[
                                tw`p-[1.5rem 1rem] hover:text-blue-300`,
                                activePage === id &&
                                    tw`border-b-[2px] border-b-blue-600`
                            ]}
                            href={`#${id}`}
                        >
                            {t(id)}
                        </a>
                    </li>
                ))}
            </ul>
            {/* <div tw="flex justify-between items-center w-[5rem]">
                <img src={globe} alt="Globe" />
                <CustomSelect
                    activeFilter={language}
                    activeFilterType="language"
                />
            </div> */}
        </nav>
    );
};

export default HeaderMenu;

import { theme } from "twin.macro";
import { LatLngTuple } from "leaflet";

export const centerMap: LatLngTuple = [0, 20];
export const defaultMapZoom: number = 3.25;

export const countryStyle = (country: any, activeMap: string) => ({
    fillColor:
        country.percentage >= 80
            ? activeMap === "countries"
                ? theme`colors.green.700`
                : activeMap === "subregions"
                ? theme`colors.purple.500`
                : theme`colors.turquoise.600`
            : country.percentage >= 60
            ? activeMap === "countries"
                ? theme`colors.green.300`
                : activeMap === "subregions"
                ? theme`colors.purple.400`
                : theme`colors.turquoise.500`
            : country.percentage >= 40
            ? activeMap === "countries"
                ? theme`colors.blue.800`
                : activeMap === "subregions"
                ? theme`colors.purple.300`
                : theme`colors.turquoise.400`
            : country.percentage >= 20
            ? activeMap === "countries"
                ? theme`colors.blue.400`
                : activeMap === "subregions"
                ? theme`colors.purple.200`
                : theme`colors.turquoise.300`
            : country.percentage >= 0
            ? activeMap === "countries"
                ? theme`colors.yellow.200`
                : activeMap === "subregions"
                ? theme`colors.purple.100`
                : theme`colors.turquoise.100`
            : theme`colors.white`,
    fillOpacity: 1,
    weight: 0.04,
    color: theme`colors.blue.800`
});

import "App.css";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import eng from "i18n/en.json";
import fra from "i18n/fr.json";
import TagManager from "react-gtm-module";
import { AfricanSocialProtectionContextProvider } from "context/africanSocialProtection";
import ErrorBoundary from "components/ErrorBoundary";
import AfricaProtection from "layouts";

const tagManagerArgs = {
    gtmId: "G-FZXR921QV5",
    dataLayerName: "pageView"
};

TagManager.initialize(tagManagerArgs);

i18n.use(initReactI18next)
    .use(detector)
    .init({
        supportedLngs: ["eng", "fra"],
        resources: {
            eng: {
                translation: eng
            },
            fra: {
                translation: fra
            }
        },
        fallbackLng: "eng",
        interpolation: {
            escapeValue: true
        }
    });

const App = () => {
    TagManager.dataLayer(tagManagerArgs);

    return (
        <ErrorBoundary>
            <AfricanSocialProtectionContextProvider>
                <AfricaProtection />
            </AfricanSocialProtectionContextProvider>
        </ErrorBoundary>
    );
};

export default App;
